import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import styles from '../scss/about.scss';

class About extends Component {
  render() {
    return (
      <div className={styles.about}>
        <div className="about__header">About</div>
      </div>
    );
  }

}

const mapStateToProps = (state) => ({
  settings: state.settings,
});

export default connect(mapStateToProps, { })(withRouter(About));
