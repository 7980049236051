import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Modal } from 'antd';
import '../scss/whatsapp-notify.scss';
import { t, apiGetInvoice } from '../tool';

class WhatsappNotify extends Component {
  constructor(props){
    super(props);
    const root = document.getElementById('root');
    root.classList.add('page-whatsappNotify');
    const invoiceId = props.match.params.invoiceId;
    apiGetInvoice(invoiceId).then(data=>{
      if (data.invoice){
        this.setState({invoice: data.invoice});
      }
    });
    this.state = {whatsappClicked: false, invoice: null};
  }

  shouldComponentUpdate(props){
    return true;
  }

  componentDidUpdate(){

  }

  componentWillUnmount(){
    const root = document.getElementById('root');
    root.classList.remove('page-whatsappNotify');
  }

  goMenu = ()=>{
    Modal.destroyAll();
    this.props.history.push('/' + this.props.project + '/menu');
  }

  goInvoice = ()=>{
    Modal.destroyAll();
    this.props.history.push('/' + this.props.project + '/invoice-record');
  }

  goPayBill = ()=>{
    Modal.destroyAll();
    this.props.history.push('/' + this.props.project + '/pay-bill');
  }

  goWhatsapp = ()=>{
    let phone = this.props.settings.orderWhatsapp;
    phone = phone.length === 8?'852'+phone:phone;
    const msg = this.prepareMessage();
    let url = 'https://wa.me/' + phone + '?text=' + msg;
    this.setState({whatsappClicked: true});
    window.open(url, '_blank');
    this.goInvoice();
  }

  parseCart(json){
    json = json.replace(/\\"/g,"\"");
    json = json.replace(/\\u/g,"u");
    return JSON.parse(json);
  }

  prepareMessage(){
    const invoice = this.state.invoice;
    const cart = this.parseCart(invoice.cart);
    const br ='LineBreak';
    let msg = '訂單#' + invoice.id + br;
    cart.forEach(item=>{
      msg = msg + item.cname + '  X  1  ($' + item.amount + ')' + br;
      item.options.forEach((e,i)=>{
        msg = msg + '--- ' + item.properties[i].cname + ' -- ' + item.properties[i].items[e].cname + br;
      });
    });
    msg = msg + '=======' + br + '合計$'+invoice.amount + br;
    msg = msg + '=======' + br + '客戶名稱:' + invoice.name + br;
    msg = msg + '=======' + br + '電話:' + invoice.phone + br;
    msg = msg + '=======' + br + '地址:' + (invoice.address?invoice.address:'外賣自取') + br;
    msg = msg + '=======' + br + '付款狀態:' + (invoice.paid?invoice.paid:'N/A') + br;
    msg = encodeURIComponent(msg);
    msg = msg.replace(/LineBreak/g, '%0A');
    return msg;
  }

  componentDidMount(){
  }

  render() {
    console.log(this.props.cart);
    const invoice = this.state.invoice;    
    if (invoice){
      let msg = 'whatsapp-notification';
      if (invoice.paid === 'paypal'){
        msg = 'whatsapp-notification-2';
      }
      const msg2 = {text: 'whatsapp-notification-3', style: {color: 'red', fontWeight: 'bold'} };
      return (
        <div className="WhatsappNotify">
          <div className="WhatsappNotify__panel">
            <div className="WhatsappNotify__submit" onClick={this.goWhatsapp}>{t('go-whatsapp')}</div>
          </div>
          <div className="WhatsappNotify__topic" onClick={this.goWhatsapp}>
            <span>{t(msg)}</span>
            <span style={msg2.style}>{t(msg2.text)}</span>
          </div>
          <div className="WhatsappNotify__logo"></div>
        </div>
      );
    } else {
      return (
      <div className="WhatsappNotify">
        <div className="WhatsappNotify__loading"></div>
      </div>)
    }
  }

}

const mapStateToProps = (state) => ({
  baseHref: state.core.baseHref,
  project: state.core.project,
  cart: state.cart.cart,
  cartLength: state.cart.cartLength,
  invoices: state.cart.invoices,
  settings: state.db.settings,
  pages: state.db.pages,
  lang: state.core.lang,
  table: state.core.table,
});

const mapDispatchToProps = (dispatch) =>{
  return {
    // postInvoice: (invoice) => {dispatch(postInvoice(invoice))},
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WhatsappNotify));
