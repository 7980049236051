import React, {Component} from 'react';
import { Provider } from "react-redux";
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import './App.scss';
import store from "./store";
import { Nav, Home, Menu, About, Preload, Checkout, Invoice, CustomPage, CartButton, WhatsappNotify } from './components'; 
import { apiGetSetting, t } from './tool';
import { registerProject } from './actions/coreActions';
import { loadSetting } from './actions/dbActions';
import { loadInvoices } from './actions/cartActions';

class App extends Component{
  constructor(props){
    super(props);
    const project = this.getProject();
    this.state = {ready: false, project: project, cartLength: 0};
    this.store = store;
    setTimeout(()=>{this.liveUpdate();},2000);
  }

  shouldComponentUpdate(props){
    return true;
  }

  componentDidMount(){
    let project = this.getProject();
    store.dispatch(loadInvoices());
    store.dispatch(registerProject(project));
    apiGetSetting(project).then(res=>{
      if (res.settings){
        res.project = project;
        store.dispatch(loadSetting(res));
        this.setState({ready: true});
      } else {
        window.location.href = '/html/404.html';
      }
    });
  }

  getProject(){
    const urlParams = new URLSearchParams(window.location.search);
    const pathnameArr = window.location.pathname.split('/');
    if (this.state){
      return this.state.project;
    } else if (urlParams.get('project')){
      return urlParams.get('project');
    } else if (pathnameArr.length >= 2){
      return window.location.pathname.split('/')[1];
    } else {
      return 'demo';
    }
  }

  myCopyrightClick = (e)=>{
    window.open('https://www.5businesshk.com');
    return;
  };

  cartClick = (e)=>{
    let url = '/' + this.state.project + '/checkout';
    if (this.cartLength === 0){
      url = '/' + this.state.project + '/menu';
    }
    this.props.history.push(url);
    // history.push(url);
  };

  liveUpdate(){
    const cartLength = this.state.cartLength;
    if (cartLength !== this.cartLength){
      this.setState({cartLength: this.cartLength});
    }
    setTimeout(()=>{
      this.liveUpdate();
    },500);
  }

  renderPreload(){
    let { core, db } = store.getState();
    let images = [];
    if (db.carousel){
      images = db.carousel.map(i=> core.baseHref + 'carousel/' + i);
    }
    return <Preload images={images} ready={()=>{this.setState({ready: true}) } }/>
  }

  get cartLength(){
    const cart = this.store.getState().cart;
    return cart.cartLength;
  }

  render(){
    const project = this.state.project;
    const year = {year: new Date().getFullYear()};
    return !this.state.ready?this.renderPreload():(
      <Provider store={this.store}>
        <BrowserRouter>
          <Nav></Nav>
          <Switch>
            <Route exact path="/">
              <Redirect to={`/${project}/home`} />
            </Route>
            <Route exact path="/:project">
              <Redirect to={`/${project}/home`} />
            </Route>
            <Route path="/:project/home/:table?">
              <Home />
            </Route>
            <Route path="/:project/menu/:menuId?">
              <Menu/>
            </Route>
            <Route path="/:project/about">
              <About/>
            </Route>
            <Route path="/:project/checkout">
              <Checkout/>
            </Route>
            <Route path="/:project/invoice-record">
              <Invoice/>
            </Route>
            <Route path="/:project/whatsapp-notify/:invoiceId?">
              <WhatsappNotify/>
            </Route>
            <Route path="/:project/:page">
              <CustomPage/>
            </Route>
          </Switch>
          <div className="my-copyright" onClick={this.myCopyrightClick}>
            <span>{t('my-copyright', year)}</span>
          </div>
          <CartButton/>
        </BrowserRouter>
      </Provider>
    )
  }

}

export default App;
