import React from "react";

function TopShadow(props){
  const style = {
    height: '5px',
    backgroundColor: '#fff',
    width: '100%',
    position: 'absolute',
    background: 'linear-gradient(to bottom,rgba(0,0,0,.1) 0,rgba(0,0,0,0) 100%)',
    zIndex: 1
  };
  let className = props.className??'';
  return <div style={style} className={'top-shadow ' + className}></div>
}

export default TopShadow;