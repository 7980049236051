import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import '../scss/category.scss';

class Category extends PureComponent {
  constructor(props){
    super(props);
    this.initStyle(props.category);
  }

  initStyle(category){
    this.style = {};
    if (category.color){
      this.style.color =category.color;
    }
    if (category.bgColor){
      this.style.backgroundColor = category.bgColor;
    }
    // if (category.image){
    //   this.style.backgroundImage = "url('" + this.props.baseHref + 'product/' + category.image;
    // }
  }

  categoryClick = (e)=>{
    let history = this.props.history;
    let url = '/' + this.props.project + '/menu/' + this.props.category.id;
    history.push({pathname: url, category: this.props.category});
  }

  render() {
    const { image } = this.props.category;
    const name = this.props.lang === 'tc'?'cname':'ename';
    if (true){ //photo + text
      return (
        <div className={'Category ' + (!image?'no-image':'')} style={this.style} onClick={(e)=>{ this.categoryClick(e)}}>
          { image? <img src={this.props.baseHref + 'product/' + image} alt={image}/>:''}
          <div className="Category__topic">{ this.props.category[name] }</div>
        </div>
      );
    } else {

    }

  }

}

const mapStateToProps = (state) => ({
  baseHref: state.core.baseHref,
  project: state.core.project,
  menus: state.core.menus,
  lang: state.core.lang
});

export default connect(mapStateToProps, { })(withRouter(Category));
