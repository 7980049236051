import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, BackTop, Modal, Radio, Input, Form, Select } from 'antd';
import {DeleteFilled,CopyFilled,UpSquareTwoTone } from '@ant-design/icons';
import '../scss/checkout.scss';
import { cloneCart, removeCart, clearCart, postInvoice } from '../actions/cartActions';
import { t, apiSubmitCart } from '../tool';
const { Option } = Select;

class CheckOut extends Component {
  constructor(props){
    super(props);
    let verify = '' + (Math.floor(Math.random() * 1000));
    verify = verify.padStart(3,'0');
    this.state = {verify: verify, clicking: false, billed: false, submitted: false};
  }

  shouldComponentUpdate(props){
    if (props.cartLength === 0){
      this.goMenu();
      return false;
    }
    return true;
  }

  componentDidUpdate(){

  }

  setClicking(){
    this.setState({clicking: true});
    setTimeout(()=>{
      this.setState({clicking: false});
    },2000);
  }

  goMenu = ()=>{
    if (this.state.clicking){
      return;
    }
    this.setClicking();
    Modal.destroyAll();
    this.props.history.push('/' + this.props.project + '/menu');
  }

  goInvoice = ()=>{
    if (this.state.clicking){
      return;
    }
    this.setClicking();
    Modal.destroyAll();
    this.props.history.push('/' + this.props.project + '/invoice-record');
  }

  goPayBill = ()=>{
    if (this.state.clicking){
      return;
    }
    this.setClicking();
    Modal.destroyAll();
    this.props.history.push('/' + this.props.project + '/pay-bill');
  }

  clearAllClick = (e)=>{
    if (this.state.clicking){
      return;
    }
    this.setClicking();
    this.props.clearCart();
    this.goMenu();
  }

  preSubmitClick = (e)=>{
    const { TextArea } = Input;
    const form = React.createRef();
    const verify = this.state.verify;
    if (this.state.clicking || this.state.billed){
      return;
    }
    this.setState({billed: true});
    this.setClicking();
    console.log(verify);
    let seconds = 0;
    const initContent = (mode, payment, seconds = 0)=>{
      return (  
        <>
          <p>{ t('delivery-method')}</p>
          <Form name="form" initialValues={ {email: '', address:'', payment: payment, mode: mode} } ref={form} onFinish={formSubmit}>
            <div className="row__radio">
              <Form.Item name="mode">
                <Radio.Group buttonStyle="solid" onChange={(e)=>{ updateContent(e.target.value, payment) } }>
                  <Radio.Button value="take-away">{t('take-away')}</Radio.Button>
                  <Radio.Button value="on-delivery">{t('on-delivery')}</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </div>
            <p>{ t('contact-method')}</p>
            <div className="row name">
              <Form.Item name="name" rules={[{required: true, message: t('pls-input-name')}]}>
                <Input type="text" placeholder={t('pls-input-name')} />
              </Form.Item>
            </div>
            <div className="row email">
              <Form.Item name="email" rules={[{required: true, pattern: /^\S+@\S+\.\S+$/, message: t('pls-input-email2')}]}>
                <Input type="email" placeholder={t('pls-input-email')} />
              </Form.Item>
            </div>
            <div className="row phone">
              <Form.Item name="phone" rules={[{required: true, pattern: /^[0-9]+$/, message: t('pls-input-phone2')}]}>
                <Input placeholder={t('pls-input-phone')} />
              </Form.Item>
            </div>
            { mode === 'on-delivery'?
            <div className="row">
              <Form.Item name="address" rules={[{required: true, message: t('pls-input-address2')}]}>
                <TextArea rows={4} placeholder={t('pls-input-address')} />
              </Form.Item>
            </div>:'' }
            <p>{ t('payment-method')}</p>
            <div className="row__radio">
              <Form.Item name="payment">
                <Radio.Group buttonStyle="solid" onChange={(e)=>{ updateContent(mode, e.target.value) } }>
                  <Radio value="cod">{t('cod')}</Radio>
                  <Radio value="credit-card">{t('credit-card')}</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            <div className="row__button">
              <Form.Item>
                <Button type="primary" htmlType="submit">{t('submit')}</Button>
                <Button type="cancel" onClick={closeModal}>{t('cancel')}</Button>
              </Form.Item>
            </div>
          </Form>
        </>
          );
    };
/*
    const getVerifyCode = (mode, payment)=>{
      const name = form.current.getFieldValue('name');
      const email = form.current.getFieldValue('email');
      const phone = form.current.getFieldValue('phone');
      const verify = this.state.verify;
      const encode = window.btoa(JSON.stringify({name: name, email: email, phone: phone, verify: verify}) );
      const company = this.props.settings.companyName;
      const emailValid = /^\S+@\S+\.\S+$/.test(email);
      if (emailValid){
        apiGetVerify(encode, company).then(res=>{
          console.log(res);
        });
        seconds = 120;
        let interval = setInterval(() => {
          console.log(seconds);
          if (seconds > 0){
            seconds = seconds - 1;
            updateContent(mode, payment);
          } else {
            clearInterval(interval);
          }
        }, 1000);
      } else {
        Modal.info({
          title: t('topic-error'),
          content: (
            <div>
              <p>{ t('email-invalid')}</p>
            </div>
          ),
          onOk() {},
        });
      }
    }

    const answerValidate = (f,v,c)=>{
      if (v === verify || v === ''){
        c();
      } else {
        c(t('wrong-verify'));
      }
    };
*/
    const updateContent = (mode, payment)=>{
      preSubmitModal.update({content: initContent(mode, payment, seconds)});
    };

    const closeModal = ()=>{
      this.setState({billed: false});
      preSubmitModal.destroy();
    };

    const formSubmit = (formData)=>{
      let {cart, table } = this.props;
      console.log(formData);
      if (this.state.submitted){
        return;
      }
      this.setState({submitted: true});
      if (this.state.clicking){
        return;
      }
      this.setClicking();
      if (localStorage){
        ['email','phone','address','name'].forEach(prop=>{
          if (formData[prop]){
            localStorage.setItem(prop, formData[prop]);
          }
        });
      }
      formData['createdAt'] = +new Date();
      const info = this.calSum();
      formData['priceSum'] = info.priceSum;
      formData['shippingFee'] = info.shippingFee;
      formData['priceSumShipping'] = info.priceSumShipping;
      apiSubmitCart(cart, table, formData).then(res=>{
        if (res.invoice.createdAt === false){
          res.invoice.createdAt = formData.createdAt;
        }
        this.props.postInvoice(res.invoice);
        this.props.clearCart();
        if (formData.payment === 'cod'){
          this.goWhatsappNotify(res.invoice);
        } else if (formData.payment === 'credit-card'){
          this.goPaypal(res.invoice);
        }
      }).catch(err=>{
        console.log(err);
      });
    }

    const preSubmitModal = Modal.info({
      title: t('invoice-payment-and-delivery'),
      className: 'Checkout-presubmit',
      content: initContent('take-away','cod', seconds),
      okButtonProps: { style: {display: 'none'} }
    });

    setTimeout(() => {
      if (localStorage){
        const phone = localStorage.getItem('phone');
        const email = localStorage.getItem('email');
        const address = localStorage.getItem('address');
        form.current.setFieldsValue({phone: phone, email: email, address: address});
      }
    }, 300);
  }

  goCod = (form)=>{
    Modal.success({
      title: t('invoice-submitted-title'),
      className: 'Checkout-after-cod',
      content: (
        <div>
          <div>
            <p>{ t('invoice-submitted-content')}</p>
          </div>
          <div className="Checkout__modal__panel">
            <Button shape="round" size="large" onClick={this.goMenu}>{t('go-menu')}</Button>
            <Button shape="round" size="large" onClick={this.goInvoice} >{t('go-invoice')}</Button>
          </div>
        </div>
      ),
    });
  }

  goWhatsappNotify = (invoice)=>{
    if (this.state.clicking){
      return;
    }
    this.setClicking();
    console.log(invoice);
    Modal.destroyAll();
    this.props.history.push('/' + this.props.project + '/whatsapp-notify/' + invoice.id);
  }

  goPaypal = (invoice)=>{
    setTimeout(()=>{
      let secondsToGo = 3;
      const modal = Modal.success({
        title: t('will-redirect-soon'),
        content: t('will-redirect-to-paypal-you-can-back-to-view-invoice',{sec: secondsToGo}),
        closable: false,
        okButtonProps: { style: { display: 'none' } }
      });

      const timer = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          content: t('will-redirect-to-paypal-you-can-back-to-view-invoice',{sec: secondsToGo})
        });
      }, 1000);
      
      setTimeout(() => {
        clearInterval(timer);
        modal.destroy();
        let code = '';
        const secret = 'eater' +this.props.project;
        for(let i=0;i < secret.length; i++){
          for(let j = 1 ; j < 3; j++){
            code = code + String.fromCharCode(Math.round(Math.random() * 25 + 97) )
          }
          code = code + secret.substr(i,1);
        }
        const business = this.props.settings.paypalLink;
        console.log(invoice);
        // const returnUrl = '//food.smartorder.company/' + this.props.project + '/whatsapp-notify/' + invoice.id;
        const urlSearchParams = new URLSearchParams({cmd: '_xclick', business: business, amount: invoice.amount, return: '//food.smartorder.company/admin/api?r=order.paypalSuccess&invoiceId=' + invoice.id + '&token=' + code + '&secret=' + invoice.secret, item_name:'food order#', currency_code: 'HKD'});
        let url = 'https://www.paypal.com/cgi-bin/webscr?' + urlSearchParams.toString();
        // let url = 'https://www.sandbox.paypal.com/cgi-bin/webscr?'+ urlSearchParams.toString();
        window.location.href = url; 
      }, secondsToGo * 1000);

    },500);
  }

  renderHeader(){
    const checkoutHeader = this.props.pages.find(page=> page.page_type === 'checkout-header');
    if (checkoutHeader){
      const html = this.props.lang === 'tc'? checkoutHeader.chtml: checkoutHeader.ehtml;
      return (
        <div className="Checkout__header-container" dangerouslySetInnerHTML={{ __html: html }}>
        </div>
      )
    }
  }

  renderItem(){
    let {cart, lang, cloneCart, removeCart} = this.props;
    let name = lang === 'tc'?'cname':'ename';
    const shortid = require('shortid');
    return cart.map(item=>
      <div className="Checkout__item" key={shortid.generate()}>
        <div className="first">
          <div className="Checkout__item__unit">1 x {t(item.unit)}</div>
          <div className="Checkout__item__name">{item[name]}</div>
          <div className="Checkout__item__price">${item.amount}</div>
          <Button icon={<CopyFilled />} type="primary" shape="circle" size="large" onClick={(e)=>{cloneCart(item)} }></Button>
          <Button icon={<DeleteFilled />} type="danger" shape="circle" size="large" onClick={(e)=>{removeCart(item)} }></Button>
        </div>
        { item.properties.length > 0? this.renderItemProp(item):''}
      </div>
    )
  }

  renderItemProp(item){
    let {lang} = this.props;
    let name = lang === 'tc'?'cname':'ename';
    const shortid = require('shortid');
    item.properties.forEach(prop=>{
      prop.items.forEach(item=>{
        let label = item[name];
        if (item.price > 0){
          label = label + '(+$' + item.price + ')';
        } else if (item.price < 0){
          label = label + '(-$' + (item.price * -1) + ')';
        }
        item.label = label;
      });
    });
    return item.properties.map((prop,index)=>
      <div className="Checkout__prop" key={shortid.generate()}>
        <div className="Checkout__prop__label">{prop[name]}</div>
        <Select defaultValue={item.options[index]} onChange={(value)=>{this.handleChange(value,item,index)}}>
          { prop.items.map((item,index)=>
            <Option value={index} key={index}>{ item.label }</Option>
          )}
        </Select>
      </div>
    );
  }

  handleChange(value,item,index){
    item.options[index] = value;
    item.amount = item.price;
    item.properties.forEach((prop,index)=>{
      const choice = item.options[index];
      const addonPrice = prop.items[choice].price;
      item.amount += addonPrice?addonPrice:0;
    });
    this.forceUpdate();
  }

  calSum(){
    let priceSum = this.props.cart.reduce((p,c)=> p?p + c.amount: c.amount, 0);
    let shippingFee = 0;
    this.props.shipments.forEach(s=>{
      if (priceSum >= s.min_amount && (s.shipping_fee < shippingFee || shippingFee === 0) ){
        shippingFee = s.shipping_fee;
      }
    });
    const priceSumShipping = shippingFee + priceSum;
    return {priceSum :priceSum, shippingFee: shippingFee, priceSumShipping: priceSumShipping};
  }

  renderSum(){
    const info = this.calSum();
    let {cartLength} = this.props;
    return (
    <>
    <div className="Checkout__shipping-fee">
      <div className="Checkout__shipping-fee__label">{t('shipping-fee')}</div>
      <div className="Checkout__shipping-fee__fee">${info.shippingFee}</div>
    </div>
    <div className="Checkout__sum">
      <div className="Checkout__sum__label">{t('total')}</div>
      <div className="Checkout__sum__qty">{cartLength + ' ' + t('pc') + (info.shippingFee > 0?t('incl-ship'):'') }</div>
      <div className="Checkout__sum__price">${info.priceSumShipping}</div>
    </div>
    <div className="Checkout__panel">
      <Button type="primary" shape="round" onClick={this.preSubmitClick}>{t('submit')}</Button>
      <Button shape="round" onClick={this.clearAllClick}>{t('clear-all')}</Button>
    </div>
    </>)
  }

  renderFooter(){
    const checkoutFooter = this.props.pages.find(page=> page.page_type === 'checkout-footer');
    if (checkoutFooter){
      const html = this.props.lang === 'tc'? checkoutFooter.chtml: checkoutFooter.ehtml;
      return (
        <div className="Checkout__footer-container" dangerouslySetInnerHTML={{ __html: html }}>
        </div>
      )
    }
  }

  componentDidMount(){
  }

  render() {
    return (
      <div className="Checkout">
        { this.renderHeader() }
        { this.renderSum() }
        { this.renderItem() }
        { this.renderFooter() }
        <BackTop visibilityHeight={30}>
          <UpSquareTwoTone twoToneColor="#52c41a" style={{ fontSize: '40px'}} />
        </BackTop>
      </div>
    );
  }

}

const mapStateToProps = (state) => ({
  baseHref: state.core.baseHref,
  project: state.core.project,
  cart: state.cart.cart,
  cartLength: state.cart.cartLength,
  invoices: state.cart.invoices,
  settings: state.db.settings,
  pages: state.db.pages,
  lang: state.core.lang,
  shipments: state.db.shipments,
  table: state.core.table,
});

const mapDispatchToProps = (dispatch) =>{
  return {
    removeCart: (item) => {dispatch(removeCart(item))},
    cloneCart: (item) => {dispatch(cloneCart(item))},
    clearCart: () => {dispatch(clearCart())},
    postInvoice: (invoice) => {dispatch(postInvoice(invoice))},
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CheckOut));
