import React, { Component } from "react";
import { Link, withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import '../scss/nav.scss';
import {TopShadow} from './index';
import { Modal } from 'antd';
import {t} from '../tool';

class Nav extends Component {
  navItems = [];
  isOn = false;
  constructor(props){
    super(props);
    this.navItems = [
      'home','menu','invoice-record'
    ];
    this.state = {isOn: false};
  }

  componentDidMount(){

  }

  logoClick = (e)=>{
    let history = this.props.history;
    let url = '/' + this.props.project + '/home';
    if (this.props.table){
      url = url + '/' + this.props.table;
    }
    history.push(url);
    
  }

  buttonClick = (e)=>{
    this.setState((state)=>{return {isOn: !state.isOn} });
  }

  cartClick = (e)=>{
    if (this.props.cartLength > 0){
      this.props.history.push('/' + this.props.project + '/checkout');
    } else {
      Modal.info({
        title: t('cart-is-empty-title'),
        content: (
          <div>
            <p>{ t('cart-is-empty-content')}</p>
          </div>
        ),
        onOk() {},
      });
    }
  }

  renderNavItem(items){
    let shortid = require('shortid');
    return <><div className={'navlist ' + (this.state.isOn?'active':'')}>
      {
        items.map(i=>
          <Link key={shortid.generate()} to={"/" + this.props.project + '/' + i} onClick={this.buttonClick}>
            <span>{t(i)}</span>
          </Link>
        )
      }
      </div></>;
  }

  render() {
    let logoImage = this.props.baseHref + 'logo.png';
    if (this.props.settings.logo){
      //https://food.smartorder.company/project/ken/upload/
      logoImage = '//food.smartorder.company/project/' + this.props.project + '/upload/' + this.props.settings.logo;
    }
    return (
      <>
      <nav className="navbar">
        <img className="navbar__img" src={logoImage} alt="logo" onClick={this.logoClick}/>
      </nav>
      <TopShadow className="Nav__top-shadow__first" />
      </>
    );
  }

}

const mapStateToProps = (state) => ({
  project: state.core.project,
  baseHref: state.core.baseHref,
  table: state.core.table,
  lang: state.core.lang,
  cartLength: state.cart.cartLength,
  settings: state.db.settings
});

export default connect(mapStateToProps, { })(withRouter(Nav));
