import { ADD_CART, MINUS_CART, CLONE_CART, CLEAR_CART, POST_INVOICE, LOAD_INVOICES } from "./types";

export const addCart = (item) => (dispatch) => {
  let cartItem:any = {productId: item.id, cname: item.cname, ename: item.ename, unit: item.unit, price: item.price, properties: item.properties?? item.default?? [],options:[], amount: item.price };
  if (cartItem.properties.length > 0){
    cartItem.options = cartItem.properties.map(prop => {
      cartItem.amount += prop.items[0].price;  
      return 0;
    });
  }
  dispatch({type: ADD_CART, payload: {cartItem} });
}

export const removeCart = (cartItem) => (dispatch) => {
  dispatch({type: MINUS_CART, payload: {cartItem} });
}

export const cloneCart = (cartItem) => (dispatch) => {
  dispatch({type: CLONE_CART, payload: {cartItem} });
}

export const clearCart = () => (dispatch) => {
  dispatch({type: CLEAR_CART, payload: {} });
}

export const postInvoice = (invoice) => (dispatch)=>{
  dispatch({type: POST_INVOICE, payload: {invoice} });
}

export const loadInvoices = ()=>(dispatch)=>{
  dispatch({type: LOAD_INVOICES, payload: {} });
}
