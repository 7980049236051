import { REGISTER_PROJECT, REGISTER_TABLE, CHANGE_LANG } from "../actions/types";

const initState = { project: "demo", table: '', lang: 'tc', baseHref: '/'};

export default function (state = initState, action) {
  switch (action.type) {
    case REGISTER_PROJECT:
      return { ...state, project: action.payload.project, baseHref: action.payload.baseHref };
    case REGISTER_TABLE:
      return { ...state, table: action.payload.table };
    case CHANGE_LANG:
      if (localStorage){
        localStorage.setItem('lang',action.payload.lang);
      }
      return {...state, lang: action.payload.lang};
    default:
      return state;
  }
}
