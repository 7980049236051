import { LOAD_SETTING } from "./types";

function getChild(parent, menuItems){
  let children = menuItems.filter(i=> i.parent === parent.id);
  if (children){
    parent.children = children.map(ch=>{
      getChild(ch, menuItems);
      ch.parent = parent;
      return ch;
    });
  }
}

export const loadSetting = (data) => (dispatch) => {
  let menus = {id: 0, name: 'root', children: data.menus.filter(e=>e.parent === 0)};
  menus.children.forEach(child=>{
    getChild(child, data.menus);
    child.parent = menus;
  });
  data.products = data.products.filter(p=> p.cname);
  data.products.forEach(product=>{
    product.price = parseFloat(product.price);
    if (product.cat){
      product.cat = product.cat.split(',').map(e=> parseInt(e));
    } else {
      product.cat = [];
    }
    try{
      product.properties = JSON.parse(product.json);
      product.properties.forEach(prop=>{
        prop.items.forEach(item=>{
          item.price = parseFloat(item.price);
        });
      });
    } catch(e){
      product.properties = [];
    }
  });
  data.pages.forEach(page=>{
    page.ehtml = atob(page.ehtml);
    page.chtml = atob(page.chtml);
    const replacement = 'src="//food.smartorder.company/project/';
    page.chtml = page.chtml.replace(/src="\/project\//g, replacement);
  });
  if (data.dicts && localStorage){
    data.dicts.forEach(row=>{
      localStorage.setItem('tc_'+ row.key, row.tc?row.tc:row.key);
      localStorage.setItem('en_'+ row.key, row.en?row.en:row.key);
    });
  }
  data.shipments.forEach(s=>{
    s.min_amount = s.min_amount?parseInt(s.min_amount):0;
    s.shipping_fee = s.shipping_fee?parseInt(s.shipping_fee):0;
  });
  data.shipments = data.shipments.filter(e=>e.status === 1).sort((a,b)=>{
    if (a.min_amount > b.min_amount){
      return 1;
    } else if (a.min_amount < b.min_amount){
      return -1;
    } else {
      return 0;
    }
  });
  
  dispatch({type: LOAD_SETTING, payload: {settings: data.settings, menus: menus, carousels: data.carousels, products: data.products, pages: data.pages, shipments: data.shipments}})
}
  
  