import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import '../scss/invoice.scss';
import { t , d } from '../tool';

class Invoice extends Component {
  constructor(props){
    super(props);
    const root = document.getElementById('root');
    root.classList.add('page-invoice');
    // apiSubmitCart(cart, table, formData).then(res=>{
    //   this.props.postInvoice(res.invoice);
    //   this.props.clearCart();
    //   if (formData.payment === 'cod'){
    //     this.goWhatsappNotify(res.invoice);
    //   } else if (formData.payment === 'credit-card'){
    //     this.goPaypal(res.invoice);
    //   }
    // }).catch(err=>{
    //   console.log(err);
    // });
  }

  shouldComponentUpdate(props){
    return true;
  }

  componentDidUpdate(){

  }

  componentDidMount(){
  }

  componentWillUnmount(){
    const root = document.getElementById('root');
    root.classList.remove('page-invoice');
  }

  renderCartItem(cart){
    let {lang} = this.props;
    let name = lang === 'tc'?'cname':'ename';
    let shortid = require('shortid');
    console.log(cart);
    if (Array.isArray(cart) === false){
      try{
        cart = JSON.parse(cart);
      } catch(e){
        cart = [];
      }
    } else if (cart.every(c=> c.properties) === false){
      cart = [];
    }
    return cart.map(item =>( 
      <div className="Invoice__cart-item" key={shortid.generate()}>
        <div className="first">
          <div className="Invoice__cart-item__unit">1 {t(item.unit)}</div>
          <div className="Invoice__cart-item__name">{item[name]}</div>
          <div className="Invoice__cart-item__price">${item.price}</div>
        </div>
        { item.properties.length > 0? this.renderCartItemProp(item): ''}
      </div>
    ))
  }

  renderCartItemProp(item){
    return '';
  }

  renderInvoices(){
    let shortid = require('shortid');
    if (this.props.invoices.length > 0){
      const invoices = this.props.invoices.sort((a,b)=>{
        if (a.createdAt < b.createdAt){
          return 1;
        } else if (a.createdAt > b.createdAt){
          return -1;
        } else {
          return 0;
        }
      })
      return invoices.map(invoice=> (
        <div className="Invoice__item" key={shortid.generate()}>
          <div className="Invoice__header">
            <div className="Invoice__num">{t('invoice-num') + ':' + invoice.id}</div>
            <div className="Invoice__time">{d(invoice.createdAt)}</div>
          </div>
          <div className="Invoice__cart">
            { this.renderCartItem(invoice.cart)}
          </div>
          <div className="Invoice__footer">
            <div className="Invoice__price__label">{ t('total-amount') }</div>
            <div className="Invoice__price">{ invoice.amount }</div>
          </div>
        </div>
      ))
    } else {
      return <div className="Invoice__empty"></div>
    }
  }

  render() {
    const project = this.props.project;
    return (
      <div className="Invoice">
        <h2 className="Invoice__topic">{t('invoice-record')}</h2>
        <Link className="Invoice__back" to={'/'+project}>
          <span>Back</span>
        </Link>
        { this.renderInvoices() }
        {/* <BackTop visibilityHeight={30}>
          <UpSquareTwoTone twoToneColor="#52c41a" style={{ fontSize: '40px'}} />
        </BackTop> */}
      </div>
    );
  }

}

const mapStateToProps = (state) => ({
  baseHref: state.core.baseHref,
  project: state.core.project,
  invoices: state.cart.invoices,
  settings: state.db.settings,
  lang: state.core.lang,
  table: state.core.table
});

const mapDispatchToProps = (dispatch) =>{
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Invoice));
