import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducers from "./reducers";

let invoices = [];
if (localStorage){
  let json = localStorage.getItem('invoices');
  invoices = json?JSON.parse(json): [];
}
const initState = { 
  core: { project: "demo", table: '', lang: 'tc', baseHref: '/'}, 
  db: { settings: {}, menus: {}, carousels: [], products: [], pages: [], shipments: [] }, 
  cart: { cart: [], cartLength: 0, lastChangeItemId: 0, invoices: invoices} 
};

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducers,
  initState,
  composeEnhancer(applyMiddleware(thunk))
);
export default store;
