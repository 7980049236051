import React, { Component } from "react";
import { connect } from "react-redux";
import '../scss/cartButton.scss';
import { withRouter } from "react-router";
import {ShoppingCartOutlined} from '@ant-design/icons';

class CartButton extends Component {
  cartLength;
  constructor(props){
    super(props);
    this.cartLength = this.props.cartLength;
  }

  shouldComponentUpdate(nextProps, nextState){
    if (this.cartLength !== nextProps.cartLength){
      this.cartLength = nextProps.cartLength;
      return true;
    }
    return false;
  }

  cartClick = (e)=>{
    const project = localStorage.getItem('project');
    let url = '/' + project;
    if (this.props.cartLength > 0){
      url = url + '/checkout';
    } else {
      url = url + '/menu';
    }
    this.props.history.push(url);
  };

  render() {
    const cartLength = this.props.cartLength;
    return (
      <div className={'CartButton ' + (cartLength > 0?'has-item':'')} onClick={this.cartClick}>
        <ShoppingCartOutlined className="CartButton__icon" />
        { cartLength > 0? <div className="CartButton__len">{cartLength}</div>:''}
      </div>
    );
  }

}

const mapStateToProps = (state) => ({
  cartLength: state.cart.cartLength,
  project: state.project
});

export default connect(mapStateToProps, { })(withRouter(CartButton));
