import axios from 'axios';
import dict from './dict.json';
import * as moment from 'moment';
const api = axios.create({
  baseURL: '//food.smartorder.company/admin/api'
});

let token='';
const getToken = (project)=>{
  let code = '';
  const secret = 'eater' + project;
  for(let i=0;i < secret.length; i++){
    for(let j = 1 ; j < 3; j++){
      code = code + String.fromCharCode(Math.round(Math.random() * 25 + 97) )
    }
    code = code + secret.substr(i,1);
  }
  token = code;
  return token;
};

export const apiGetSetting = project => api.get('?r=frontend.main&token=' + getToken(project) ).then(res=>res.data);

export const apiSubmitCart = (cart, table, form) => api.post('?r=order.submitCart&token=' + token, {cart, table, form}).then(res=>res.data);
export const apiGetVerify = (encode, company)=> api.post('?r=auth.mailVerifyCode&token=' + token, {encode, company}).then(res=>res.data);
export const apiGetInvoice = (invoiceId) => api.get('?r=order.getInvoice&token=' + token + '&invoiceId=' + invoiceId).then(res=>res.data);

export const t = (key, defWord = null) => {
  let lang = localStorage.getItem('lang')?? 'tc';
  let inDict = dict.find(l=>l.key === key);
  let defaultLang = inDict? inDict[lang]:null;
  if (typeof defWord === 'object'){
    for(let prop in defWord){
      defaultLang = defaultLang.replace('{{' + prop + '}}', defWord[prop]);
    }
  }
  return localStorage.getItem(lang + '_' + key) ?? defaultLang ?? defWord ?? key;
}

export const d = (value, format='YYYY-MM-DD HH:mm:ss') =>{
  if (value > 1000000000 && value < 2000000000){ //php timestamp
    return moment.unix(value).format(format);
  }
  return moment(new Date(value)).format(format);
}

export const cloneJson = json => JSON.parse(JSON.stringify(json));
