import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Carousel, Button, Row, Col } from 'antd';
import { registerTable } from '../actions/coreActions';
import '../scss/home.scss';
import {t} from '../tool';

class Home extends PureComponent {
  constructor(props){
    super(props);
    this.carousel = React.createRef();
    let {table} = props.match.params;
    if (table){
      props.registerTable(table);
    }
    const root = document.getElementById('root');
    root.classList.add('page-home');
  }

  componentWillUnmount(){
    const root = document.getElementById('root');
    root.classList.remove('page-home');
  }

  orderClick(e){
    let project = this.props.project;
    let history = this.props.history;
    history.push('/' + project + '/menu');
  }

  invoiceRecordClick(e){
    this.props.history.push('/' + this.props.project + '/invoice-record');
  }

  linkButtonClick(e, item){
    const name = item.name;
    if (name === 'facebook'){
      window.open(item.value, '_blank');
    } else if (name === 'phone'){
      const fullNo = item.value.substr(0, 3) === '852'?item.value: '852' + item.value;
      window.open("tel:+" + fullNo);
    } else if (name === 'whatsapp'){
      const msg ="&text=Hi,我透過網上5BusinessHK,HKOAD,Google,YouTube搵到你,我有興趣想了解更多您的服務內容,謝謝";
      const fullNo = item.value.substr(0, 3) === '852'?item.value: '852' + item.value;
      const url = "https://api.whatsapp.com/send?phone=" + fullNo + msg;
      window.open(url);
    }
  }

  renderCarousel(){
    let cItems = this.props.carousels?this.props.carousels:[];
    let carouselPath = this.props.baseHref + 'carousel/';
    let shortid = require('shortid');
    return (
      <Carousel autoplay={true} dots={false}>
        { cItems.map((item, index)=> 
          <img src={carouselPath + item} key={shortid.generate()} alt={item} />
          ) }
      </Carousel>
    );
  }

  renderHomeHeader(){
    const shortid = require('shortid');
    const homeHeaders = this.props.pages.filter(page=> page.page_type === 'home-header');
    if (homeHeaders.length > 0){
      
      return homeHeaders.map(e=> {
        return (
        <div className="Home__body-container" key={shortid.generate()} dangerouslySetInnerHTML={{ __html: e.chtml }}>
        </div>
      );
    });
    } else {
      return;
    }
  }

  renderHomeFooter(){
    const shortid = require('shortid');
    const homeFooters = this.props.pages.filter(page=> page.page_type === 'home-footer');
    if (homeFooters.length > 0){
      return homeFooters.map(e=> {
        return (
          <div className="Home__body-container" key={shortid.generate()} dangerouslySetInnerHTML={{ __html: e.chtml }}>
          </div>
        )});
    } else {
      return;
    }
  }

  renderFooter(){
    let settings = this.props.settings;
    let shortid = require('shortid');
    let link = [
      {name: 'phone', value: `${settings.phone}`},
      {name: 'instagram', value: `//instagram.com/${settings.instagram}`},
      {name: 'facebook', value: `//facebook.com/${settings.facebook}`},
      {name: 'whatsapp', value:`${settings.whatsapp}`}
    ];
    return (
      <footer>
        <div className="right">
          { link.map(item=>
            <div className="link-button" key={shortid.generate()} onClick={(e)=>{this.linkButtonClick(e,item)} }>
              <img src={'/img/' + item.name + '.svg'} alt={item.name}/>
            </div>)}
        </div>
      </footer>
    )
  }

  componentDidMount(){
  }

  render() {
    return (
      <div className="Home">
        {/* { this.renderCarousel() } */}
        { this.renderHomeHeader() }
        <Row justify="center" align="center" style={ {marginTop: 20} }>
          <Col span={20}>
            <Button type="primary" block onClick={(e)=>{ this.orderClick(e);}}>{t('order')}</Button>
          </Col>
        </Row>
        <Row justify="center" align="center" style={ {marginTop: 20}}>
          <Col span={20}>
            <Button type="primary" block onClick={(e)=>{ this.invoiceRecordClick(e);}}>{t('invoice-record')}</Button>
          </Col>
        </Row>
        { this.renderHomeFooter() }
        { this.renderFooter() }
      </div>
    );
  }

}

const mapStateToProps = (state) => ({
  project: state.core.project,
  baseHref: state.core.baseHref,
  carousels: state.db.carousels,
  settings: state.db.settings,
  pages: state.db.pages,
  lang: state.core.lang
});

const mapDispatchToProps = (dispatch) =>{
  return {
    registerTable: (table)=>{dispatch(registerTable(table) )},
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));
