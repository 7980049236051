import React, { Component } from "react";
import { connect } from "react-redux";
import '../scss/home.scss';

class Contact extends Component {
  
  constructor(prop){
    super(prop);
    console.log(prop);
  }

  routerWillLeave(nextLocation){
    console.log('routerWillLeave:');
  }

  render() {
    return (
      <div className="contact">
        <div className="contact__body">Contact</div>
      </div>
    );
  }

}

const mapStateToProps = (state) => ({
  cartItems: state.cart.items,
});

export default connect(mapStateToProps, { })(Contact);
