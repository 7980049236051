import { REGISTER_PROJECT, REGISTER_TABLE,CHANGE_LANG } from "./types";

export const registerProject = (project) => (dispatch) => {
  let baseHref = '/';
  if (window.location.host.indexOf('localhost') === -1){
    baseHref = baseHref + 'project/' + project + '/';
  }
  if (localStorage){
    localStorage.setItem('project', project);
  }
  dispatch({type: REGISTER_PROJECT, payload: {project, baseHref} });
}

export const registerTable = (table) => (dispatch) => {
  dispatch({type: REGISTER_TABLE, payload: {table} });
}

export const changeLang = (lang) => (dispatch) => {
  dispatch({type: CHANGE_LANG, payload: {lang} });
}



