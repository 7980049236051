import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from 'react-router-dom'
import { Row, Col, BackTop } from 'antd';
import { UpCircleFilled } from '@ant-design/icons';
import { Category, Product } from './index';
import '../scss/menu.scss';
import moment from 'moment';

class Menu extends Component {
  constructor(props){
    super(props);
    let menuId = props.match.params.menuId;
    if (menuId){
      let obj = this.findMenu(this.props.menus, parseInt(menuId));
      this.state = {root: obj.root, parent: obj.parent};
    } else {
      this.state = {root: this.props.menus, parent: null};
    }
  }

  shouldComponentUpdate(props,state){
    let menuId = props.match.params.menuId;
    if (this.props.match.params.menuId !== menuId){
      return true;
    } else {
      return false;
    }
  }

  componentWillReceiveProps(nextProps){
    let menuId = nextProps.match.params.menuId;
    if (menuId){
      let obj = this.findMenu(this.props.menus, parseInt(menuId));
      this.setState({root: obj.root, parent: obj.parent});
    } else {
      this.setState({root: this.props.menus, parent: null});
    }
  }

  componentDidUpdate(){
    
  }

  
  findMenu(root, id){
    let found = null;
    if (root.children){
      found = root.children.find(ch=> ch.id === id);
      if (found){
        return {root:found, parent:root};
      } else {
        root.children.forEach(ch=>{
          found = found || this.findMenu(ch, id);
          if (found){
            return found;
          }
        });
      }
    }
    return found;
  }
  
  renderMenuItems(){
    let menuItems = this.state.root.children??[];
    menuItems = menuItems.filter(e=> {
      const d = moment().format('YYYY-MM-DD');
      const start = moment(d + ' ' + (e.time_start?e.time_start:'00:00:00'));
      const end = moment(d + ' ' + (e.time_end?e.time_end:'00:00:00'));
      return e.time_control !== 1 || (start.isAfter() && end.isBefore());
    });
    let root = this.state.root;
    let shortid = require('shortid');
    return menuItems.map(i=><Col xs={12} sm={12} md={8} lg={6} key={shortid.generate()}><Category key={shortid.generate()} category={i} parent={root} /></Col>)
  }

  renderSubMenuItems(){
    let html = this.renderBacktoParent();
    return html + (<div>test</div>);
  }

  renderBacktoParent(){
    let parent = this.state.parent;
    let to = '/' + this.props.project + '/menu';
    if (parent.id > 0){
      to = to + '/' + parent.id;
    }
    return (
    // <Col xs={12} sm={12} md={12} lg={12}>
      <Link className="Menu__back" to={to}>
        <span>Back</span>
      </Link>
    // </Col>
    )
  }

  renderProductItems(){
    let products = this.props.products.filter(product=> product.cat.indexOf(this.state.root.id) > -1);
    products = products.filter(e=> {
      const d = moment().format('YYYY-MM-DD');
      const start = moment(d + ' ' + (e.time_start?e.time_start:'00:00'));
      const end = moment(d + ' ' + (e.time_end?e.time_end:'00:00'));
      return e.time_control !== 1 || (start.isAfter() && end.isBefore());
    });
    if (products.length > 0){
      let shortid = require('shortid');
      return(  
        <>
          <div className="separator"></div>
          <Row justify="start" align="middle" gutter={[{xs: 8, sm: 16, md: 24, lg: 32}, {xs: 8, sm: 16, md: 24, lg: 32}]}>
          { products.map(product=> 
            <Col xs={12} sm={12} md={8} lg={6} key={shortid.generate()}>
              <Product product={product} />
            </Col>)
            }
          </Row>
        </>
      )
    }
  }

  renderMain(){
    const project = this.props.project;
    const backMain = ()=>{this.props.history.push('/'+ project +'/')};
    return (
      <Col className="Menu__back-main" xs={12} sm={12} md={8} lg={6} onClick={backMain}>
        <span>Back</span>
      </Col>
    )
  }

  render() {
    const hasParent = this.state.parent;
    return (
      <div className="Menu">
        <Row justify="start" align="middle" gutter={[{xs: 16, sm: 16, md: 16, lg: 16}, {xs: 16, sm: 16, md: 16, lg: 16}]}>
          { hasParent?this.renderBacktoParent():this.renderMenuItems() }
          { hasParent?'': this.renderMain() }
        </Row>
          { this.renderProductItems() }
        <BackTop>
          <UpCircleFilled />
        </BackTop>
      </div>
    );
  }

}

const mapStateToProps = (state) => ({
  project: state.core.project,
  baseHref: state.core.baseHref,
  menus: state.db.menus,
  products: state.db.products
});

export default connect(mapStateToProps, { })(withRouter(Menu));
