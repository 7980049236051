import React from "react";
import { connect } from "react-redux";

const Seo = (props) => {
  let title = props.title;
  let description = props.description;
  return (
    <head>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
    </head>
    )
}

const mapStateToProps = (state) => ({
  project: state.core.project,
  title: state.settings.title,
  description: state.settings.description
});

export default connect(mapStateToProps, {})(Seo);