import {
    LOAD_SETTING,
    FILTER_PRODUCTS_BY_SIZE,
    ORDER_PRODUCTS_BY_PRICE,
  } from "../actions/types";
  
  const initState = { settings: {}, menus: {}, carousels: [], products: [], pages: [] };
  export default function (state = initState, action) {
    switch (action.type) {
      case LOAD_SETTING:
        let {settings,menus,carousels,products,pages,shipments} = action.payload;
        return { ...state, settings: settings, menus: menus, carousels: carousels, products: products, pages: pages, shipments: shipments };
      case FILTER_PRODUCTS_BY_SIZE:
        return {
          ...state,
          filteredItems: action.payload.items,
          size: action.payload.size,
        };
      case ORDER_PRODUCTS_BY_PRICE:
        return {
          ...state,
          filteredItems: action.payload.items,
          sort: action.payload.sort,
        };
  
      default:
        return state;
    }
  }
  