import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import { Button } from 'antd';
import { addCart, removeCart } from '../actions/cartActions';
// import { t } from '../tool'; 
import '../scss/product.scss';

class Product extends Component {
  constructor(props){
    super(props);
    this.initStyle(props.product);
  }

  initStyle(product){
    this.style = {};
    if (product.color){
      this.style.color = product.color;
    }
    if (product.bgColor){
      this.style.backgroundColor = product.bgColor;
    }
    if (product.image){
      // this.style.backgroundImage = "url('" + this.props.baseHref + 'product/' + category.image;
    }
  }

  shouldComponentUpdate(props){
    return props.lastChangeItemId === props.product.id;
  }

  render() {
    let product = this.props.product;
    let qty = this.props.cart.filter(i=> i.productId === product.id).length;
    let lang = this.props.lang;
    let unit = lang==='tc'?'cunit':'eunit';
    let name = lang==='tc'?'cname':'ename';
    let info = lang==='tc'?'cinfo':'einfo';
    let addClick = this.props.addClick;
    let minusClick = this.props.minusClick;
    let plusSvg = '/img/plus.svg';
    let minusSvg = '/img/minus.svg';
    return (
      <div className="Product">
        <div className="Product__img">
          <img src={this.props.baseHref + 'product/' + product.image} alt={product.image} />
        </div>
        <div className="Product__detail">
          <div className="Product__meta">
            <h5 className="Product__meta__title">{ product[name] }</h5>
            <div className="Product__meta__info">{ product[info] }</div>
          </div>
          <div className="Product__panel">
            <Button type="primary" shape="circle" size="large" className="Product__panel__add" onClick={(e)=>{addClick(product)}} >
              <img src={plusSvg} alt="plus.svg" />
            </Button>
            <div className="Product__panel__qty">{qty} {product[unit]}</div>
            <Button type="primary" shape="circle" size="large" className="Product__panel__minus" onClick={(e)=>{minusClick(product)} }>
              <img src={minusSvg} alt="minus.svg" />
            </Button>
          </div>
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state) => ({
  baseHref: state.core.baseHref,
  project: state.core.project,
  lang: state.core.lang,
  cart: state.cart.cart,
  cartLength: state.cart.cartLength,
  lastChangeItemId: state.cart.lastChangeItemId
});

const mapDispatchToProps = (dispatch) =>{
  return {
    addClick: (product)=>{dispatch(addCart(product) )},
    minusClick: (product)=>{dispatch(removeCart(product) )},
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Product));
