import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { t } from '../tool';
import '../scss/customPage.scss';

class CustomPage extends Component {
  renderNotFound(){
    let homeLink = '/' + this.props.project + '/home';
    return (
    <div className="CustomPage__404">
		  <div className="notfound">
			  <div className="notfound-404">
				  <div></div>
				  <h1>404</h1>
			  </div>
			  <h2>Page not found</h2>
			  <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
			  <Link to={homeLink}>{t('go-home')}</Link>
		  </div>
	  </div>)
  }
  
  render() {
    let {page} = this.props.match.params;
    let customPage = this.props.settings.pages.find(template=> template.key === page);
    if (!customPage){
      let page404 = this.props.settings.pages.find(template=> template.key === 'page-404');
      if (page404){
        let html = page404.html;
        return (<div className="CustomPage__container" dangerouslySetInnerHTML={{ __html: html }}></div>)  
      } else {
        return this.renderNotFound();
      }
    } else {
      let html = customPage.html;
      return (<div className="CustomPage__container" dangerouslySetInnerHTML={{ __html: html }}></div>)
    }
  }

}

const mapStateToProps = (state) => ({
  settings: state.db.settings,
  project: state.core.project
});

export default connect(mapStateToProps, { })(withRouter(CustomPage));
