import React, { Component } from "react";
// import { ActivityIndicator } from 'antd';
import '../scss/preload.scss';

class Preload extends Component {
  constructor(props){
    super(props);
    this.state = {images: [...new Set(props.image)]};
  }

  imageLoad(image){
    let filtered = this.state.images.filter(i=> i !== image);
    this.setState((state)=>{
      state.images = filtered
    });
    if (filtered.length === 0){
      this.props.ready();
    }
  }

  render() {
    let shortid = require('shortid');
    let imgStyle = {display: 'none'};
    return (
      <div className="preload">
        { this.props.images.map(i => <img style={imgStyle} src={i} alt={i} key={shortid.generate()} onLoad={(e)=>{ this.imageLoad(i)}} />)}
        {/* <ActivityIndicator size="large" text="Loading..."/> */}
      </div>
    );
  }

}

export default Preload;
