import { ADD_CART, MINUS_CART,CLONE_CART,CLEAR_CART,POST_INVOICE,LOAD_INVOICES } from "../actions/types";
import { cloneJson } from '../tool';
const initState = { cart: [], cartLength: 0, lastChangeItemId: 0};
export default function (state = initState, action) {
  if (action.type === ADD_CART){
    state.cart.push(action.payload.cartItem);
    return { ...state, cart: state.cart, cartLength: state.cart.length, lastChangeItemId: action.payload.cartItem.productId };

  } else if (action.type === MINUS_CART){
    let index = -1;
    if (action.payload.cartItem.productId){
      index = state.cart.findIndex(cartItem=> cartItem === action.payload.cartItem);
    } else {
      index = state.cart.findIndex(cartItem=> cartItem.productId === action.payload.cartItem.id);
    }
    if (index > -1){
      let lastChangeItemId = state.cart[index].productId;
      state.cart.splice(index, 1);
      return { ...state, cart: state.cart, cartLength: state.cart.length, lastChangeItemId: lastChangeItemId };
    } else {
      return state;  
    }

  } else if(action.type === CLONE_CART){
    let item = action.payload.cartItem;
    let index = state.cart.findIndex(cartItem=> cartItem === item);
    let newItem = cloneJson(item);
    state.cart.splice(index + 1, 0, newItem);
    return { ...state, cart: state.cart, cartLength: state.cart.length };
  
  } else if (action.type === CLEAR_CART){
    return {...state, cart: [], cartLength: 0, lastChangeItemId: 0}

  } else if (action.type === LOAD_INVOICES){
    if (localStorage){
      let json = localStorage.getItem('invoices');
      let invoices = json?JSON.parse(json):[];
      invoices = invoices.filter(e=> e);
      return {...state, invoices};
    }
  } else if (action.type === POST_INVOICE){
    let invoice = action.payload.invoice;
    debugger;
    let swap = invoice.cart.replace(/\\"/g,"\"");
    swap = swap.replace(/\\u/g,"u");
    invoice.cart = JSON.parse(swap);
    state.invoices.push(invoice);
    if (localStorage){
      localStorage.setItem('invoices', JSON.stringify(state.invoices));
    }
    const shippingFee = action.payload.shippingFee?action.payload.shippingFee:0;
    return { ...state, invoices: state.invoices, cart: [], cartLength: 0, shippingFee: shippingFee};
  } else {
    return state;
  }
}
